// NOTE: native
import React from 'react'
import ReactDOM from 'react-dom/client'
// TODO: hooks
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AuthContextProvider } from './context/AuthContext'
// TODO: components
import App from './App'
import Toaster from './components/ui/Sonner'
// TODO: assets
import 'chart.js/auto'
import './assets/css/index.css'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<AuthContextProvider>
				<Toaster closeButton richColors />
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</AuthContextProvider>
		</QueryClientProvider>
	</React.StrictMode>
)
