interface PageTitleProps {
	src?: any
	title: string,
	className: string
}

const PageTitle = ({ title, className, src }: PageTitleProps) => {
	return (
		<div className={`flex ${className} mb-4 mt-2`}>
			{/*<img src={src} className="my-auto mr-2 h-12 md:h-14" alt={title} />*/}
			<h1 className="my-auto text-3xl font-medium">{title}</h1>
		</div>
	)
}
export default PageTitle
