// TODO: clean this component and break down in a folder
// TODO: create a separate file for the links, desktop and phone components

// NOTE: hooks
import { useEffect, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useAuthContext } from '../../../hooks/useAuthContext'
// TODO: have this hook in the hooks folder as it's used in multiple places
import useSetup from '../../../pages/setup/useSetup'
// NOTE: components
import Module from '../Card'
import Modal from '../Modal'
//import Tooltip from '@mui/material/Tooltip'
// NOTE: images
import avatarPlaceholderImage from '../../../assets/img/placeholder.png'
import helfyIcon from '../../../assets/img/logo/helfyIcon.png'

import {
	FaGear as SettingsIcon,
	FaArrowRightFromBracket as LogoutIcon,
	FaListCheck as CheckIcon
} from 'react-icons/fa6'

const NavBar = ({ links }: any) => {
	const { loading, lifestyle, focus, kits } = useSetup()
	const { user } = useAuthContext()
	const { logout } = useAuth()
	const [show, setShow] = useState(false)
	const [completeAccount, setCompleteAccount] = useState(false)

	useEffect(() => {
		if (loading) return

		setCompleteAccount(
			!lifestyle ||
			lifestyle.length === 0 ||
			!focus ||
			focus.length === 0 ||
			!kits ||
			kits.length === 0
		)
	}, [loading])

	return (
		<>
			<Modal show={show} onHide={() => setShow(false)} size="sm">
				<Modal.Header onHide={() => setShow(false)}>Hi, {user.fname}!</Modal.Header>

				<div className="border-b-2 border-[#f6f8f9] p-4">
					<NavLink
						className="flex-fill flex w-full flex-col"
						to="/account"
						onClick={() => setShow(false)}
					>
						<img
							src={user.profilePicture ?? avatarPlaceholderImage}
							alt="Profile"
							className="mx-auto h-28 rounded-full border-2 border-bloodOrange p-1 shadow-inner"
							loading="eager"
						/>
						<p className="font-regular mt-2 text-center text-lg">{user?.email}</p>
					</NavLink>
				</div>

				<div className="divide-y divide-slate-100 px-4 pb-4 text-slate-600">
					<NavLink
						className="my-2 flex flex-wrap-reverse gap-2 px-0 py-4 text-xl sm:px-4"
						to="/account/setup"
						onClick={() => setShow(false)}
					>
						<div className="flex gap-2 sm:gap-4">
							<CheckIcon className="my-auto" />

							<p className="my-auto">Complete your account</p>
						</div>

						{completeAccount && (
							<span className="my-auto ml-auto flex h-fit w-fit rounded-full bg-red-100 px-2 py-1 text-xs font-bold text-red-800">
								Action required
							</span>
						)}
					</NavLink>
					<NavLink
						className="my-2 flex gap-3 px-0 py-4 text-xl sm:gap-4 sm:px-4"
						to="/account"
						onClick={() => setShow(false)}
					>
						<SettingsIcon className="my-auto" />
						<p className="my-auto">Settings</p>
					</NavLink>

					<button
						className="my-2 flex w-full gap-3 px-0 py-4 text-xl sm:gap-4 sm:px-4"
						onClick={logout}
					>
						<LogoutIcon className="my-auto" />
						<p className="my-auto">Log out</p>
					</button>
				</div>
			</Modal>

			<nav>
				<PhoneNav
					links={links}
					profilePicture={user.profilePicture ?? avatarPlaceholderImage}
					completeAccount={completeAccount}
					openAccount={(e: any) => {
						e.preventDefault()
						setShow(true)
					}}
					className="block md:hidden"
				/>

				<DeskNav
					links={links}
					profilePicture={user.profilePicture ?? avatarPlaceholderImage}
					completeAccount={completeAccount}
					openAccount={(e: any) => {
						e.preventDefault()
						setShow(true)
					}}
					className="hidden md:block"
				/>
			</nav>
		</>
	)
}

interface PhoneNavProps {
	links: any,
	profilePicture: any,
	openAccount: any,
	className: string,
	completeAccount: any
}

const PhoneNav = ({ links, profilePicture, openAccount, className, completeAccount }: PhoneNavProps) => {
	return (
		<Module
			className={`${className} no-scrollbar fixed bottom-0 z-20`}
			style={{
				backgroundColor: 'rgba(255, 255, 255, 0.9)',
				boxShadow: 'rgba(149, 157, 165, 0.2) 0px 0px 24px',
				overflowX: 'scroll',
				border: 'none',
				borderRadius: '0',
				width: '100%'
			}}
		>
			<div className="flex justify-around p-2">
				{links.map((link: any) => (
					<div key={link.to}>
						<NavLink
							className={({ isActive }) =>
								`${isActive ? 'text-green-900 hover:text-green-900' : 'text-slate-500'} flex h-full flex-col p-3`
							}
							to={link.to}
							end={link.text?.toLowerCase() === 'home'}
						>
							{({ isActive }) => (
								<span className="mx-auto h-auto w-[34px] transition-all duration-700 ease-in-out">
									{isActive ? link.activeIcon : link.inactiveIcon}
								</span>
							)}
						</NavLink>
					</div>
				))}
				<NavLink className="my-auto" to="/account" end={false} onClick={openAccount}>
					{({ isActive }) => (
						<>
							{profilePicture && (
								<div className="relative">
									<img
										src={profilePicture}
										style={{
											width: 40,
											height: 40,
											maxWidth: 40,
											maxHeight: 40,
											borderRadius: '50%',
											padding: 2
										}}
										className={`${isActive ? 'border-bloodOrange' : 'border-slate-400'} border-2 shadow-inner transition-all duration-700 ease-in-out`}
										alt="Profile"
										loading="eager"
									/>
									{completeAccount && (
										<div className="absolute right-0 top-0 size-4 rounded-full bg-bloodOrange" />
									)}
								</div>
							)}

							{!profilePicture && (
								<div
									className="skeleton border-2 border-slate-400"
									style={{
										width: 40,
										height: 40,
										maxWidth: 40,
										maxHeight: 40,
										borderRadius: '50%',
										padding: 2
									}}
								/>
							)}
						</>
					)}
				</NavLink>
			</div>
		</Module>
	)
}

interface DeskNavProps {
	links: any, 
	profilePicture: any, 
	openAccount: any, 
	className: string, 
	completeAccount: any
}

const DeskNav = ({ links, profilePicture, openAccount, className, completeAccount }: DeskNavProps) => {
	return (
		<div
			style={{
				height: '100vh',
				flexShrink: 0,
				backgroundColor: '#f1f1f1',
				position: 'sticky',
				bottom: 0,
				top: 0
			}}
			className={`${className} w-[110px] lg:w-[260px]`}
		>
			<div className="h-[100%] w-[100%] border-[2px] border-slate-200 bg-white">
				<div className="flex flex-col p-2" style={{ height: '100%', overflowY: 'scroll' }}>
					<Link to="/" className="mx-auto mb-2 mt-6">
						<img
							src={helfyIcon}
							alt="The Wellbeing Doctors"
							className="h-[65px] lg:h-[120px]"
							loading="eager"
						/>
					</Link>

					{links.map((link: any) => (
						<div key={link.to}>
							<NavLink
								className={({ isActive }) =>
									`${isActive ? 'text-green-900 hover:text-green-900 lg:border-slate-200 lg:bg-slate-50' : 'text-slate-500'} nav-link mt-3 flex px-4 py-3`
								}
								to={link.to}
								end={link.text?.toLowerCase() === 'home'}
							>
								{({ isActive }) => (
									<>
										<span className="mx-auto h-auto w-[34px] transition-all duration-700 ease-in-out lg:mx-0 lg:w-[30px]">
											{isActive ? link.activeIcon : link.inactiveIcon}
										</span>
										<span className="my-auto hidden pl-4 lg:inline">{link.text}</span>
									</>
								)}
							</NavLink>
						</div>
					))}

					<div className="mt-auto flex flex-col">
						<NavLink
							className={({ isActive }) =>
								`${isActive ? 'text-green-900 hover:text-green-900 lg:border-slate-200 lg:bg-slate-50' : 'text-slate-500'} nav-link mb-8 mt-4 flex px-0 py-2 lg:px-4`
							}
							to="/account"
							end={false}
							onClick={openAccount}
						>
							{({ isActive }) => (
								<>
									{profilePicture && (
										<div className="relative mx-auto lg:mx-0">
											<img
												src={profilePicture}
												className={`${isActive ? 'border-bloodOrange' : 'border-slate-400'} mx-auto size-[58px] rounded-full border-2 p-[2px] shadow-inner transition-all duration-700 lg:mx-0 lg:size-[46px]`}
												alt="Profile"
												loading="eager"
											/>
											{completeAccount && (
												<div className="absolute right-0 top-0 size-5 rounded-full bg-bloodOrange lg:size-4" />
											)}
										</div>
									)}
									{!profilePicture && (
										<div
											className={`skeleton mx-auto size-[58px] rounded-full border-2 border-slate-400 p-[2px] lg:mx-0 lg:size-[46px]`}
										/>
									)}
									<span className="my-auto hidden pl-4 lg:inline">Account</span>
								</>
							)}
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NavBar
