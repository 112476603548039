// TODO: check where it's being used - have the useAuthContext hook in the same file

import { useAuthContext } from './useAuthContext'
import * as healthApi from '../api/health/auth'

export const useAuth = () => {
	const { dispatch } = useAuthContext()

	// NOTE: remove user from storage and dispatch logout action
	const logout = () => {
		localStorage.removeItem('user')
		dispatch({ type: 'LOGOUT' })
		window.location.reload()
	}

	const login = async (email: string, password: any, route: string) => {
		const data = { email, password, route }

		return await healthApi.login(data)
	}

	const register = async (data: any) => {
		return await healthApi.register(data)
	}

	const resetPwd = async (email: string, url: any) => {
		const data = { email, url }
		return await healthApi.sendToken(data)
	}

	const newPwd = async (email: string, token: any, password: any) => {
		const data = { email, token, password }
		return await healthApi.resetPwd(data)
	}

	return { login, register, newPwd, resetPwd, logout }
}
